import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="cart-box"

export default class extends Controller {
  static outlets = ["add-cart"];
  static targets = ["message", "button", "input"];
  static values = {
    items: { type: Array, default: [] },
    max: Number,
  };

  updateBox() {
    const itemsSize = this.itemsValue.length;

    if (itemsSize == 0) {
      this.element.classList.remove("show");
    } else {
      this.element.classList.add("show");
    }

    if (this.hasMaxValue) {
      // Max exsit only on flavours listing
      const diff = this.maxValue - itemsSize;

      if (this.hasMessageTarget) {
        // Message target exist only on flavours listing
        if (diff == 0) {
          this.messageTarget.classList.add("hide");
        } else if (diff == 1) {
          this.messageTarget.innerText = "Scegli ancora un gusto per aggiungere al carrello";
          this.messageTarget.classList.remove("hide");
        } else if (diff > 1) {
          this.messageTarget.innerText = `Scegli ancora ${diff} gusti per aggiungere al carrello`;
          this.messageTarget.classList.remove("hide");
        }
      }

      if (diff == 0) {
        this.buttonTarget.classList.remove("disabled");
        this.buttonTarget.disabled = false;
      } else {
        this.buttonTarget.classList.add("disabled");
        this.buttonTarget.disabled = true;
      }

      this.addCartOutlets.forEach((element) => {
        element.increaseDisabledValue = diff == 0;
      });
    }
  }

  itemsValueChanged(value) {
    this.inputTarget.value = value.join();
    this.updateBox();
  }
}
