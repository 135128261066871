import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="add-cart"
export default class extends Controller {
  static outlets = ["cart-box"];
  static targets = ["counter", "decrease", "increase"];
  static values = {
    increaseDisabled: { type: Boolean, default: false },
    count: { type: Number, default: 0 },
    flavour: Number,
  };

  decrease() {
    if (this.countValue == 0) {
      return;
    }
    let items = this.cartBoxOutlet.itemsValue;
    const i = items.indexOf(this.flavourValue);
    items.splice(i, 1);
    this.cartBoxOutlet.itemsValue = items;
    this.countValue = this.countValue - 1;
  }

  increase() {
    if (this.increaseDisabledValue) {
      return;
    }
    this.countValue = this.countValue + 1;
    this.cartBoxOutlet.itemsValue = this.cartBoxOutlet.itemsValue.concat(this.flavourValue);
  }

  countValueChanged(value) {
    this.counterTarget.innerHTML = value;

    if (value == 0) {
      this.decreaseTarget.classList.add("disabled");
    } else {
      this.decreaseTarget.classList.remove("disabled");
    }
  }

  increaseDisabledValueChanged(value) {
    if (value) {
      this.increaseTarget.classList.add("disabled");
    } else {
      this.increaseTarget.classList.remove("disabled");
    }
  }
}
