import { Controller } from "@hotwired/stimulus";
import { navigator } from "@hotwired/turbo";

// Connects to data-controller="cart-line"
export default class extends Controller {
  static targets = ["link"];
  static values = { name: String };

  delete(e) {
    e.preventDefault();

    if (window.confirm(`Vuoi cancellare il prodotto "${this.nameValue}"?`)) {
      let form = document.createElement("form");
      form.setAttribute("method", "delete");
      form.setAttribute("action", this.linkTarget.getAttribute("href"));
      navigator.submitForm(form);
    }
  }
}

// import { Turbo } from "@hotwired/turbo-rails";

// const postToUrl = (path, params = {}, method = "post") => {
//   let form = document.createElement("form");
//   form.setAttribute("method", method);
//   form.setAttribute("action", path);

//   Object.entries(params).forEach(([name, value]) => {
//     let hiddenField = document.createElement("input");
//     hiddenField.setAttribute("type", "hidden");
//     hiddenField.setAttribute("name", name);
//     hiddenField.setAttribute("value", value);
//     form.appendChild(hiddenField);
//   });

//   Turbo.navigator.submitForm(form);
// };
